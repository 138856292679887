import React, { useState, FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Login from '../../views/Login'
import LoginInput from '../../components/LoginInput'
import { asyncFetchRegister } from '../../redux/user/async'
import { selectUserLoading, selectUser, selectUserToken } from '../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileFormProps } from '../../redux/user/interfaces'

import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { Button } from 'antd'
import translate from '../../translate'

import { EVENT_URL, LANGUAGE, API_KEY, APP_VERSION } from '../../services/consts'

interface AgreementProps {
  id: number
  content: string
  title: string
  is_required: boolean
  url: string
  awfUrl?: string[]
}

const RegisterPage: FC = () => {
  const history = useHistory()
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const loading = useSelector(selectUserLoading)

  const dispatch = useDispatch()

  const [doublePrice, setDoublePrice] = useState<boolean>(false)
  const [registerForm, setRegisterForm] = useState({
    password: '',
    repeatPassword: '',
    photo: '',
    firstname: '',
    lastname: '',
    networking_on: false,
    post: '',
    status: 'Wychowanie fizyczne',
    company: 'Piknik',
    phone: '',
    email: '',
    user_agreements: ['1', '6', '3'],
  })

  const [userAgreements, setUserAgreements] = useState([])

  async function getData(url = '') {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'App-Version': APP_VERSION || '',
        'Api-Key': API_KEY,
      },
    })
    return response.json()
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (validate()) {
      const body: ProfileFormProps = registerForm
      switch (body.company) {
        case 'Piknik': {
          body.company += doublePrice
            ? ' , 300 , z osobą towarzyszącą'
            : ' , 150 , bez osoby towarzyszącej'
          break
        }
        case 'Piknik , 150 , bez osoby towarzyszącej':
        case 'Piknik , 300 , z osobą towarzyszącą': {
          body.company = doublePrice
            ? 'Piknik , 300 , z osobą towarzyszącą'
            : 'Piknik , 150 , bez osoby towarzyszącej'
          break
        }
        case 'Bal': {
          body.company += doublePrice
            ? ' , 400 , z osobą towarzyszącą'
            : ' , 200 , bez osoby towarzyszącej'
          break
        }
        case 'Bal , 200 , bez osoby towarzyszącej':
        case 'Bal , 400 , z osobą towarzyszącą': {
          body.company = doublePrice
            ? 'Bal , 400 , z osobą towarzyszącą'
            : 'Bal , 200 , bez osoby towarzyszącej'
          break
        }
        case 'Piknik + Bal': {
          body.company += doublePrice
            ? ' , 700 , z osobą towarzyszącą'
            : ' , 350 , bez osoby towarzyszącej'
          break
        }
        case 'Piknik + Bal , 700 , z osobą towarzyszącą':
        case 'Piknik + Bal , 350 , bez osoby towarzyszącej': {
          body.company = doublePrice
            ? 'Piknik + Bal , 700 , z osobą towarzyszącą'
            : 'Piknik + Bal , 350 , bez osoby towarzyszącej'
          break
        }
      }

      dispatch(asyncFetchRegister({ additionalHeaders: {}, body }))
    }

    e.preventDefault()
  }

  const validate = () => {
    if (registerForm.password !== registerForm.repeatPassword) {
      dashboardMessage(DashboardMessageTypes.error, 'Wprowadzone hasła różnią się od siebie!')
      return false
    }

    return true
  }

  useEffect(() => {
    if (user !== null && token !== null) {
      history.goBack()
    }
  }, [user, token])

  useEffect(() => {
    getData(`${EVENT_URL}/${LANGUAGE}/api/agreements/`).then((data) => {
      const agreements = [...data]
      agreements.forEach((el) => {
        if (el.id === 6) {
          el.content = ''
          el.title =
            'Umowa Powierzenia Przetwarzania Danych Osobowych przy świadczeniu usługi Aplikacji Meetinga i jej kolejnych Instancji'
        }
        if (el.id === 4) {
          el.content = ''
          el.title = 'Polityka prywatności, zgoda RODO, klauzula informacyjna SAAWF'
          el.url = 'https://meetinga.com/polityka_prywatnosci_meetinga.pdf'
          el.awfUrl = [
            'http://absolwenci-awf-warszawa.pl/rodo/',
            'https://meetinga.com/klauzula_informacyjna_saawf.pdf',
          ]
        }
        if (el.id === 3) {
          el.content =
            '<p>Rejestrując się wyrażam zgodę dla firmy Visent Coders Sp. z o.o. właściciela aplikacji Meetinga na przechowywanie i przetwarzanie danych osobowych dla potrzeb procesu realizacji zamówienia zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz.U. nr 133, poz. 883). Pełen zapis dot. polityki prywatności znajduje się na stronie.</p>'
          el.title =
            'Przetwarzanie danych osobowych w celu świadczenia usługi oraz przetwarzanie danych osobowych przez SAAWF'
          el.url = 'http://absolwenci-awf-warszawa.pl/rodo/'
        }
        if (el.id === 1) {
          el.content = '<p>Rejestrując się, akceptuję Regulamin Visent Coders Sp. z.o.o.</p>'
          el.title = 'Akceptacja regulaminu'
        }
      })
      setUserAgreements(data.filter((el: AgreementProps) => el.id === 4))
    })
  }, [])

  return (
    <Login>
      <div className="login-page">
        <h1>{translate('register_awf')}</h1>

        <form onSubmit={handleSubmit}>
          <div className="login-page-form-row">
            <div className="login-page-form-row__item">
              <LoginInput
                label={translate('name')}
                name="firstname"
                minLength={2}
                required
                placeholder={translate('name')}
                onChange={setRegisterForm}
              />
            </div>
            <div className="login-page-form-row__item">
              <LoginInput
                label={translate('surname')}
                name="lastname"
                minLength={2}
                required
                placeholder={translate('surname')}
                onChange={setRegisterForm}
              />
            </div>
          </div>
          <LoginInput
            label={translate('email')}
            name="email"
            required
            placeholder={translate('email')}
            type="email"
            onChange={setRegisterForm}
          />
          <LoginInput
            label={translate('phone_number')}
            name="phone"
            placeholder={translate('phone_number')}
            type="tel"
            pattern="[0-9]{9}"
            title="Numer telefonu powinien zawierać 9 cyfr."
            onChange={setRegisterForm}
          />

          <LoginInput
            label={translate('year_of_graduation')}
            name="post"
            minLength={4}
            maxLength={4}
            pattern="^(19|20)\d{2}$"
            type="text"
            required
            onChange={setRegisterForm}
          />
          <label htmlFor="status">
            {translate('field_of_study')}
            <span className="required"> *</span>
          </label>
          <select
            name="status"
            id="status"
            className="login-page__input"
            required
            onChange={(e) => {
              setRegisterForm((prev) => {
                return { ...prev, status: e.target.value }
              })
            }}
          >
            <option value="Wychowanie fizyczne">Wychowanie fizyczne</option>
            <option value="Sport">Sport</option>
            <option value="Turystyka i rekreacja">Turystyka i rekreacja</option>
            <option value="Fizjoterapia">Fizjoterapia</option>
            <option value="Pielęgniarstwo">Pielęgniarstwo</option>
            <option value="Terapia zajęciowa">Terapia zajęciowa</option>
          </select>

          <div>
            <p>Wpłaty na konto: 92 1090 1014 0000 0001 2951 9661</p>
            <p>Stowarzyszenie Absolwentów AWF w Warszawie</p>
            <p>Wpłaty zza granicy BIC/SWIFT/WBKPPLPP</p>
            <p>
              Tytułem: Zjazd Absolwentów 2022, imię i nazwisko uczestnika, rok ukończenia studiów
            </p>
          </div>

          <label htmlFor="company">
            {translate('i_want_to_participate_in')}
            <span className="required"> *</span>
          </label>
          <>
            <label className="login-page-checkbox__container login-page-checkbox__container--awf">
              Piknik cena 150 zł
              <input
                type="radio"
                value="Piknik"
                name="company"
                checked={
                  registerForm.company === 'Piknik' ||
                  registerForm.company === 'Piknik , 300 , z osobą towarzyszącą' ||
                  registerForm.company === 'Piknik , 150 , bez osoby towarzyszącej'
                }
                onChange={(e) => {
                  setRegisterForm((prev) => {
                    return { ...prev, company: e.target.value }
                  })
                }}
              />
              <span className="login-page-checkbox__checkmark"></span>
            </label>
          </>
          <>
            <label className="login-page-checkbox__container login-page-checkbox__container--awf">
              Bal cena 200 zł
              <input
                type="radio"
                value="Bal"
                name="company"
                checked={
                  registerForm.company === 'Bal' ||
                  registerForm.company === 'Bal , 400 , z osobą towarzyszącą' ||
                  registerForm.company === 'Bal , 200 , bez osoby towarzyszącej'
                }
                onChange={(e) => {
                  setRegisterForm((prev) => {
                    return { ...prev, company: e.target.value }
                  })
                }}
              />
              <span className="login-page-checkbox__checkmark"></span>
            </label>
          </>
          <label className="login-page-checkbox__container login-page-checkbox__container--awf">
            Piknik + Bal cena 350 zł
            <input
              type="radio"
              value="Piknik + Bal"
              name="company"
              checked={
                registerForm.company === 'Piknik + Bal' ||
                registerForm.company === 'Piknik + Bal , 700 , z osobą towarzyszącą' ||
                registerForm.company === 'Piknik + Bal , 350 , bez osoby towarzyszącej'
              }
              onChange={(e) => {
                setRegisterForm((prev) => {
                  return { ...prev, company: e.target.value }
                })
              }}
            />
            <span className="login-page-checkbox__checkmark"></span>
          </label>

          <label htmlFor="doublePrice">
            {translate('accompanying_person')}
            <span className="required"> *</span>
          </label>

          <label className="login-page-checkbox__container login-page-checkbox__container--awf">
            Tak
            <input
              type="radio"
              value="Yes"
              name="doublePrice"
              onChange={() => {
                setDoublePrice(true)
              }}
            />
            <span className="login-page-checkbox__checkmark"></span>
          </label>

          <label className="login-page-checkbox__container login-page-checkbox__container--awf">
            Nie
            <input
              type="radio"
              value="No"
              name="doublePrice"
              checked={doublePrice === false}
              onChange={() => {
                setDoublePrice(false)
              }}
            />
            <span className="login-page-checkbox__checkmark"></span>
          </label>

          <hr />
          <LoginInput
            label={translate('password')}
            name="password"
            placeholder={translate('password')}
            required
            minLength={6}
            type="password"
            onChange={setRegisterForm}
          />
          <LoginInput
            label={translate('repeat_password')}
            name="repeatPassword"
            required
            placeholder={translate('repeat_password')}
            minLength={6}
            type="password"
            onChange={setRegisterForm}
          />
          {userAgreements.length > 0 &&
            userAgreements.map((element: AgreementProps, index) => {
              return (
                <div className="login-page-checkbox__wrapper" key={index}>
                  <LoginInput
                    type="checkbox"
                    label={element.title}
                    name={String(element.id)}
                    checked={registerForm.user_agreements.find(
                      (agreement) => String(agreement) === String(element.id),
                    )}
                    onChange={setRegisterForm}
                    isUserAgreement
                    required={element.is_required}
                    url={element.url}
                    awfUrl={element.awfUrl}
                    content={element.content}
                  />
                </div>
              )
            })}
          {/* <div className="login-page-checkbox__wrapper">
            <LoginInput
              label={translate('show_me_on_networking')}
              name="networking_on"
              type="checkbox"
              checked={registerForm.networking_on}
              onChange={setRegisterForm}
            />
          </div> */}
          <Button type="primary" loading={loading} htmlType="submit">
            {translate('register')}
          </Button>
        </form>

        <div className="login-page__links">
          <span>
            {translate('have_account')}{' '}
            <Link className="login-page__link" to="/logowanie">
              {translate('sign_in')}
            </Link>
          </span>
          <Link className="login-page__link" to="/przypomnij-haslo">
            {translate('not_remember_password')}
          </Link>
        </div>
      </div>
    </Login>
  )
}

export default RegisterPage
