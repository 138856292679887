import React, { FC } from 'react'
import logo from '../../../images/AWFlogo.svg'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'
import translate from '../../translate'

const Login: FC = ({ children }) => {
  return (
    <div className="login-site">
      <div className="login-site__content">
        <img src={logo} alt="AWF" className="login-site__logo" />
        {children}
      </div>
      <div className="login-site__aside login-aside">
        <h2>{translate('login_create_event_1')}</h2>
        <p>{translate('login_create_event_2')}</p>
        <div className="login-aside__roadmap">
          {/* <img src={demo} alt="Demo" /> */}
          <div className="login-aside-roadmap__left">
            <div>
              <span className="login-aside-roadmap__unique-dot"></span>
            </div>
            <div>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
            <div>
              <span></span>
            </div>
          </div>
          <div className="login-aside-roadmap__right">
            <div>
              <span>{translate('login_option_1')}</span>
            </div>
            <div>
              <span>{translate('login_option_2')}</span>
            </div>
            <div>
              <span>{translate('login_option_3')}</span>
            </div>
            <div>
              <span>{translate('login_option_4')}</span>
            </div>
          </div>
        </div>
        <div className="login-aside__owner">
          <p>
            {translate('login_help_1')}
            <br />
            {translate('login_help_2')}
          </p>
          <div className="login-aside-owner__contact">
            <div className="login-aside-owner__contact-wrapper">
              <Icon
                name={Icons.mail}
                color={IconsColor.white}
                hoverColor={IconsColor.xiaomi}
                type={IconsFillType.fill}
              />
              <span>hello@meetinga.com</span>
            </div>
            <div className="login-aside-owner__contact-wrapper">
              <Icon
                name={Icons.phoneCall}
                color={IconsColor.white}
                hoverColor={IconsColor.xiaomi}
                type={IconsFillType.fill}
              />
              <span>+48 798 896 837</span>
            </div>
          </div>
          <br />
          <span>
            Visent Coders Sp. z o. o., ul. Chłodna 22a/14, 00-891 Warszawa, <br />
            KRS: 0000572906; NIP: 1251635038; REGON: 362396842
          </span>
          <br />
          <span>v1</span>
        </div>
      </div>
    </div>
  )
}

export default Login
