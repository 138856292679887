import React from 'react'
import Dashboard from '../../views/Dashboard'
import logo from '../../../images/AWFlogo.svg'

const Welcome: React.FC = () => {
  return (
    <Dashboard activePage="welcome">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="welcome__wrapper">
            <img src={logo} alt="AWF" />
            <h1>Witaj na wydarzeniu SAAWF!</h1>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Welcome
