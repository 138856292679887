import { createIntl, createIntlCache } from 'react-intl'
import { Languages } from './utils/enums'

export const messages = {
  en: {
    i_want_to_participate_in: 'I want to participate in',
    year_of_graduation: 'Year of graduation',
    field_of_study: 'Field of study',
    accompanying_person: 'Accompanying Person (the above price is double counted)',
    contact: 'Contact',
    agenda: 'Agenda',
    gallery: 'Gallery',
    access: 'Access',
    exhibitors: 'Exhibitors',
    favorites: 'Favorites',
    people: 'People',
    networking: 'Networking',
    show_me_on_networking: 'Show me on networking',
    notes: 'Notes',
    articles: 'Articles',
    back: 'Back',
    title: 'Title',
    content: 'Content',
    save: 'Save',
    copied_URL: 'URL copied to clipboard',
    materials: 'Materials',
    add_note: 'Add note',
    edit_note: 'Edit note',
    settings: 'Settings',
    logout: 'Logout',
    sign_in: 'Sign in',
    sign_in_text:
      'Log in with the details provided when creating an account or registering for any event',
    login: 'Login',
    no_account: "You don't have an account?",
    have_account: 'You have an account?',
    register: 'Register',
    register_awf: 'Application form Congress of the AWF',
    not_remember_password: "You don't you remember the password?",
    edit_profile: 'Edit profile',
    settings_agreements: 'Settings and agreements',
    agreements: 'Agreements',
    change_password: 'Change password',
    password: 'Password',
    repeat_password: 'Repeat password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    remind_password: 'Remind password',
    remind_password_text:
      'A link to reset your password will be sent to your registered email address',
    open_menu: 'Open menu',
    name: 'Name',
    surname: 'Surname',
    position: 'Position',
    company: 'Company',
    phone_number: 'Phone number',
    phone_number_err: 'Phone number should have 9 digits',
    delete_account: 'Delete account',
    delete_account_text:
      'After deleting your account, you will no longer have access to any events',
    delete_account_modal_text: 'Are you sure you want to delete your account?',
    email: 'Email',
    cancel: 'Cancel',
    confirm: 'Confirm',
    required: 'Required',
    see_more: 'See more',
    all_events: 'All events',
    fav_events: 'Favorite events',
    archive_events: 'Archive events',
    enter_code: 'Enter code',
    add_event: 'Add event',
    manage_events: 'Manage events',
    stand: 'Stand',
    information: 'Information',
    chat: 'Chat',
    streaming: 'Streaming',
    live: 'Live',
    video_call: 'Video call',
    attachments: 'Attachments',
    localization: 'Localization',
    write_a_message: 'Write a message',
    send: 'Send',
    networking_is_off:
      'You are not visible in networking, update your settings to chat with people',
    ask_question: 'Ask question',
    your_question: 'Your question',
    day: 'Day',
    poll: 'Poll',
    poll_unavailable: 'Poll unavailable',
    enter_only_digits: 'Enter only digits',
    description: 'Description',
    show_all: 'Show all',
    map: 'Map',
    login_create_event_1: 'Do you want to create your event?',
    login_create_event_2: 'See how easy it is!',
    login_option_1: 'Log in',
    login_option_2: 'Choose a package',
    login_option_3: 'Pay for or provide the code',
    login_option_4: 'Start the event',
    login_help_1: 'Do you have questions or need help?',
    login_help_2: 'Contact us!',
  },
  pl: {
    i_want_to_participate_in: 'Chcę uczestniczyć w',
    year_of_graduation: 'Rok ukończenia studiów',
    field_of_study: 'Kierunek studiów',
    accompanying_person: 'Osoba towarzysząca (powyższa cena jest liczona podwójnie)',
    contact: 'Kontakt',
    agenda: 'Agenda',
    gallery: 'Galeria',
    access: 'Dostęp',
    exhibitors: 'Wystawcy',
    favorites: 'Ulubione',
    people: 'Prelegenci',
    networking: 'Networking',
    show_me_on_networking: 'Pokaż mnie w networkingu',
    articles: 'Artykuły',
    back: 'Powrót',
    title: 'Tytuł',
    content: 'Treść',
    save: 'Zapisz',
    notes: 'Notatki',
    copied_URL: 'Skopiowano URL do schowka',
    materials: 'Materiały',
    add_note: 'Dodaj notatkę',
    edit_note: 'Edytuj notatkę',
    settings: 'Ustawienia',
    logout: 'Wyloguj',
    sign_in: 'Zaloguj się',
    sign_in_text:
      'Zaloguj się z danymi podanymi przy zakładaniu konta lub rejestracji na dowolne wydarzenie',
    login: 'Login',
    no_account: 'Nie masz konta?',
    have_account: 'Masz już konto?',
    register: 'Zarejestruj',
    register_awf: 'Formularz zgłoszeniowy Zjazd AWF',
    not_remember_password: 'Nie pamiętasz hasła?',
    edit_profile: 'Edytuj profil',
    settings_agreements: 'Ustawienia i zgody',
    agreements: 'Zgody',
    change_password: 'Zmień hasło',
    change_password_text: 'Ustaw nowe hasło do swojego konta',
    password: 'Hasło',
    repeat_password: 'Powtórz hasło',
    new_password: 'Nowe hasło',
    repeat_new_password: 'Powtórz nowe hasło',
    remind_password: 'Przypomnij hasło',
    remind_password_text:
      'Link do resetowania hasła zostanie wysłany na zarejestrowany adres email',
    open_menu: 'Otwórz menu',
    name: 'Imię',
    surname: 'Nazwisko',
    position: 'Stanowisko',
    company: 'Firma',
    phone_number: 'Numer telefonu',
    phone_number_err: 'Numer telefonu powinien zawierać 9 cyfr',
    delete_account: 'Usuń konto',
    delete_account_text: 'Po usunięciu konta nie będziesz miał już dostępów do żadnych wydarzeń',
    delete_account_modal_text: 'Czy na pewno chcesz usunąć swoje konto?',
    email: 'Adres email',
    cancel: 'Anuluj',
    confirm: 'Zatwierdź',
    required: 'Wymagane',
    see_more: 'Zobacz więcej',
    all_events: 'Wszystkie eventy',
    fav_events: 'Ulubione eventy',
    archive_events: 'Archiwalne eventy',
    enter_code: 'Wpisz kod',
    add_event: 'Dodaj event',
    manage_events: 'Zarządzaj eventami',
    stand: 'Stoisko',
    information: 'Informacje',
    chat: 'Chat',
    streaming: 'Streaming',
    live: 'Na żywo',
    video_call: 'Wideo rozmowa',
    attachments: 'Załączniki',
    localization: 'Lokalizacja',
    write_a_message: 'Napisz wiadomość',
    send: 'Wyślij',
    networking_is_off:
      'Masz wyłączony networking, zaktualizuj swoje ustawienia żeby czatować z ludźmi',
    ask_question: 'Zadaj pytanie',
    your_question: 'Twoje pytanie',
    day: 'Dzień',
    poll: 'Ankieta',
    poll_unavailable: 'Ankieta niedostępna',
    enter_only_digits: 'Wprowadź tylko cyfry',
    description: 'Opis',
    show_all: 'Pokaż wszystko',
    map: 'Mapa',
    login_create_event_1: 'Chcesz utworzyć swoje wydarzenie?',
    login_create_event_2: 'Zobacz jakie to proste!',
    login_option_1: 'Zaloguj się',
    login_option_2: 'Wybierz pakiet',
    login_option_3: 'Opłać lub podaj kod',
    login_option_4: 'Uruchom wydarzenie',
    login_help_1: 'Masz pytania lub potrzebujesz pomocy?',
    login_help_2: 'Skontaktuj się z nami!',
  },
}

const cache = createIntlCache()
const lang = window.localStorage.getItem('lang')

let int = createIntl(
  {
    locale: lang || 'pl',
    messages: lang === 'en' || lang === 'pl' ? messages[lang] : messages['pl'],
  },
  cache,
)

export function changeLanguage(lang: Languages) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang],
    },
    cache,
  )
  int = newIntl
}

const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values)
}

export default translate
