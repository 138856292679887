import axios from 'axios'
import { EVENT_URL, LANGUAGE, HEADERS } from './consts'

export const apiEvent = (slug: string, additionalHeaders?: any) =>
  axios.create({
    baseURL: `${EVENT_URL}/awf/${LANGUAGE}/api`,
    headers: {
      ...HEADERS,
      ...additionalHeaders,
    },
  })

export const apiEvents = (additionalHeaders?: any) =>
  axios.create({
    baseURL: `${EVENT_URL}/${LANGUAGE}/api`,
    headers: {
      ...HEADERS,
      ...additionalHeaders,
    },
  })
