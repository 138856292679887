import React, { FC } from 'react'
import handleInput from '../../utils/handleInput'
import translate from '../../translate'

interface Props {
  label: string
  name: string
  className?: string
  placeholder?: string
  minLength?: number
  maxLength?: number
  required?: boolean
  type?: string
  pattern?: string
  title?: string
  value?: string
  children?: JSX.Element | string
  checked?: boolean | number | string
  isUserAgreement?: boolean
  url?: string
  awfUrl?: string[]
  content?: string
  disabled?: boolean
  onChange: UseStateFunction
}

type UseStateFunction = (a: any) => void

const LoginInput: FC<Props> = ({
  label,
  name,
  className,
  placeholder,
  minLength,
  maxLength,
  required,
  type,
  pattern,
  title,
  children,
  value,
  checked,
  url,
  awfUrl,
  content,
  isUserAgreement,
  disabled,
  onChange,
}) => {
  return (
    <>
      {type === 'checkbox' ? (
        <>
          <label className="login-page-checkbox__container">
            {label} {required && <span className="required">*</span>}
            {content && (
              <div
                className="login-page-checkbox__content"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            )}
            {children && <span>{children}</span>}
            <input
              name={name}
              type={type}
              required={required || false}
              checked={Boolean(checked)}
              onChange={(e) => handleInput(e, onChange, type, isUserAgreement)}
            />
            <span className="login-page-checkbox__checkmark"></span>
          </label>
          {url && (
            <a href={url} target="_blank" rel="noreferrer" className="login-page-checkbox__link">
              {translate('see_more')}
            </a>
          )}
          {awfUrl &&
            awfUrl.map((el) => (
              <a
                key={el}
                href={el}
                target="_blank"
                rel="noreferrer"
                className="login-page-checkbox__link"
              >
                {translate('see_more')}
              </a>
            ))}
        </>
      ) : (
        <>
          <label htmlFor={name}>
            {label} {required && <span className="required">*</span>}
          </label>
          <input
            className={className || 'login-page__input'}
            name={name}
            placeholder={placeholder || label}
            minLength={minLength || 4}
            maxLength={maxLength || 64}
            required={required || false}
            type={type || 'text'}
            pattern={pattern}
            title={title}
            value={value}
            disabled={disabled}
            onChange={(e) => handleInput(e, onChange)}
          />
        </>
      )}
    </>
  )
}

export default LoginInput
